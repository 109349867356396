import React from "react";
import { useUser } from "./UserContext";
import AdminReviewChart from "./AdminReviewChart";
import UpcomingAppointments from "./UpcomingAppointments";

const AdminDashboardStats = () => {

    const { statisticsData } = useUser();
    const { appointments, patients, rate } = statisticsData;
    return (
        <div className="dashboard-stats">
            <div className="logo">
                <img src="https://github.com/btom7447/OAUTHC/blob/master/public/oauthc-logo%202.png?raw=true" alt="OAUTHC Logo" />
                <h6>OAUTHC</h6>
            </div>
            <div className="stats-metrics">
                <div className="stats">
                    <p>Appointment</p>
                    <h6>{appointments}</h6>
                </div>
                <hr />
                <div className="stats">
                    <p>Total Patients</p>
                    <h6>{patients}</h6>
                </div>
                <hr />
                <div className="stats">
                    <p>Rate</p>
                    <h6>{rate}</h6>
                </div>
            </div>
            <br /><br /> 
            {/* <UpcomingAppointments /> */}
            <AdminReviewChart />
        </div>
    )
};

export default AdminDashboardStats;