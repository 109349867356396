import React, { useEffect, useState } from "react";
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';

const ResearchEthicsCarousel = () => {
    const [images, setImages] = useState([]);

    useEffect(() => {
        const fetchImages = async () => {
            try {
                const response = await fetch('https://live-api.oauthc.gov.ng/v0.1/api/home/all-research-sliders');
                const data = await response.json();

                if (data.success && data.data.length > 0) {
                    // Assuming we want the images from the first item
                    setImages(data.data[0].images);
                }
            } catch (error) {
                console.error("Error fetching images:", error);
            }
        };

        fetchImages();
    }, []);

    return (
        <div className="research-carousel">
            <Splide 
                options={{
                    type: 'loop',
                    autoplay: true,
                    interval: 4000,
                    pauseOnHover: true,
                    arrows: false,
                    pagination: true,
                }}
            >
                {images.map((imageUrl, index) => (
                    <SplideSlide key={index}>
                        <img src={imageUrl} alt={`Research slide ${index + 1}`} className="research-carousel-image" />
                    </SplideSlide>
                ))}
            </Splide>
            <div className="research-button">
                <h4>Explore options to join a clinical trial</h4>
            </div>
        </div>
    );
};

export default ResearchEthicsCarousel;