import React, { useState, useEffect } from "react";
import { useUser } from "../Components/UserContext";
import AdminResearchDisplay from "../Components/AdminResearchDisplay";

const AdminResearchPage = () => {
    const { researchsData } = useUser();
    const [data, setData] = useState(researchsData);

    useEffect(() => {
        setData(researchsData);
    }, [researchsData]);

    const handleDeleteSuccess = (id) => {
        setData((prevData) => prevData.filter((item) => item.id !== id));
    };

    return (
        <div className="admin-departments-section">
            <div className="pages-caption">
                <h1>Research & Ethics</h1>
            </div>
            <div className="admin-pages-caption">
                  <h2>All Researchers</h2>
            </div>
            <AdminResearchDisplay
                data={researchsData}
                itemName="Researchers"
                entityType="admin"
                setData={handleDeleteSuccess}
            />
        </div>
    );
};

export default AdminResearchPage;