import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AdminAnnouncementsCreate = () => {
    const navigate = useNavigate();
    
    const BASE_URL = 'https://live-api.oauthc.gov.ng/v0.1/api/admin';
    const [imagePreview, setImagePreview] = useState('');

    const [formData, setFormData] = useState({
        name: '',
        content: '', 
        link: '',
        publish: false,
    });

    const [loading, setLoading] = useState(false);

    const handleSave = async (e) => {
        e.preventDefault();
    
        setLoading(true);
        const loadingToastId = toast.loading("Updating Announcement ...", {
            autoClose: false,
            toastId: 'loading-toast'
        });
    
        try {
            const token = localStorage.getItem('bearer_token');
            if (!token) {
                throw new Error('No token found. Please log in.');
            }
    
            // Create a new FormData object
            const formDataObj = new FormData();
            formDataObj.append('name', formData.name);
            formDataObj.append('content', formData.content);
            
            // Append "https://" if link is not empty
            const processedLink = formData.link && !formData.link.startsWith('http') 
                ? `https://${formData.link}` 
                : formData.link;
            
            formDataObj.append('link', processedLink);
    
            // Only append the image if a new image was selected
            if (formData.image) {
                formDataObj.append('image', formData.image);
            }
    
            const response = await fetch(`${BASE_URL}/announcement`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                body: formDataObj,
            });
    
            const result = await response.json();
    
            if (response.ok) {
                toast.update(loadingToastId, {
                    render: 'Announcement updated successfully!',
                    type: 'success',
                    autoClose: 2500,
                    isLoading: false
                });
                setTimeout(() => {
                    navigate('/admin/announcements', { replace: true });
                    window.location.reload();
                }, 2500);
            } else {
                throw new Error(result.message || 'Update failed');
            }
        } catch (err) {
            toast.update(loadingToastId, {
                render: `Error: ${err.message}`,
                type: 'error',
                autoClose: 5000,
                isLoading: false
            });
        } finally {
            setLoading(false);
            toast.dismiss('loading-toast');
        }
    };
    

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setFormData(prevData => ({
                ...prevData,
                image: file 
            }));
            const previewUrl = URL.createObjectURL(file);
            setImagePreview(previewUrl);
        } else {
            setImagePreview(formData.image ? formData.image : ''); 
        }
    };

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
    
        // For link input, ensure it’s processed to include "https://" if necessary
        const processedValue = name === 'link' && value && !value.startsWith('http')
            ? `https://${value}`
            : value;
    
        setFormData(prevData => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : processedValue 
        }));
    }; 

    return (
        <>
            <ToastContainer />
            <div>
                <div className="pages-caption">
                    <h1>Sections</h1>
                </div>
                <div className="admin-pages-caption">
                    <h2>Create New Announcement</h2>
                </div>
            </div>
            <form onSubmit={handleSave} className='details-page-form'>
                <div className="details-inputs">
                    <label>
                        Title: 
                        <input
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleInputChange}
                            placeholder="Name"
                        />
                    </label>
                    <label>
                        Link: 
                        <input
                            type="text"
                            name="link"
                            value={formData.link}
                            onChange={handleInputChange}
                            placeholder="Link"
                        />
                    </label>
                    <label>
                        Announcement Message:
                        <textarea
                            name="content"
                            value={formData.content}
                            onChange={handleInputChange}
                            placeholder="Announcement message ..."
                        />
                    </label>
                </div>

                <div className="details-publish">
                    <div className="image-box">
                        <h4>Announcement Image <br /> <span className="image-spec">(237 x 300px)</span> <br />
                        <span className="image-spec">2 Mb Max</span>
                        </h4>
                        <label>
                            <input
                                type="file"
                                name="image"
                                onChange={handleImageChange}
                            />
                            {imagePreview && (
                                <div className="image-preview">
                                    <img src={imagePreview} alt="Preview" />
                                </div>
                            )}
                        </label>
                    </div>
                    
                    <button type="submit" disabled={loading}>
                        {loading ? 'Create ...' : 'Create Announcement'}
                    </button>
                </div>
            </form>
        </>
    );
};

export default AdminAnnouncementsCreate;