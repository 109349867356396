import React, { useState, useEffect, } from "react";
import { toast, ToastContainer } from 'react-toastify';

const BASE_URL = 'https://live-api.oauthc.gov.ng/v0.1/api';

const AdminHomeCarousel = () => {
    const [loading, setLoading] = useState(false);
    const [images, setImages] = useState([]);
    const [imagePreviews, setImagePreviews] = useState({});
    const [updatedImages, setUpdatedImages] = useState({});
    const [newImage, setNewImage] = useState(null); // State to handle the new image
    const [newImagePreview, setNewImagePreview] = useState(null); // Preview for new image

    // Fetch images on component mount
    useEffect(() => {
        const fetchSliders = async () => {
            try {
                const response = await fetch(`${BASE_URL}/home/all-sliders`);
                const result = await response.json();
    
                if (result.success && result.data) {
                    setImages(result.data[0].images);
                } else {
                    throw new Error(result.message || 'Failed to retrieve sliders');
                }
            } catch (error) {
                console.error("Error fetching sliders:", error);
                toast.error("Failed to load sliders");
            }
        };
    
        fetchSliders();
    }, []);    

    // Handle image change for new images
    const handleNewImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const previewUrl = URL.createObjectURL(file);
            setNewImage(file);
            setNewImagePreview(previewUrl); // Set preview for new image
        }
    };

    // Handle image change for existing images
    const handleImageChange = (e, index) => {
        const file = e.target.files[0];
        if (file) {
            const previewUrl = URL.createObjectURL(file);

            // Update previews and updated images
            setImagePreviews(prevPreviews => ({
                ...prevPreviews,
                [index]: previewUrl
            }));

            setUpdatedImages(prevImages => ({
                ...prevImages,
                [index]: file
            }));
        }
    };

    // Handle form submit (to update the images)
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const loadingToastId = toast.loading("Updating Sliders...", {
            autoClose: false,
            toastId: 'loading-toast'
        });

        try {
            const token = localStorage.getItem('bearer_token');
            if (!token) {
                throw new Error('No token found. Please log in.');
            }

            const formData = new FormData();

            // Append updated images to formData using the correct index format (image[index])
            Object.keys(updatedImages).forEach(index => {
                formData.append(`image[${index}]`, updatedImages[index], updatedImages[index].name);
            });

            // If a new image is added, append it
            if (newImage) {
                formData.append('image[new]', newImage, newImage.name);
            }

            const response = await fetch(`${BASE_URL}/admin/update-slider/1`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                body: formData
            });

            if (!response.ok) {
                const result = await response.json();
                throw new Error(result.message || 'Failed to update sliders');
            }

            // Success
            toast.update(loadingToastId, {
                render: "Sliders updated successfully!",
                type: "success",
                isLoading: false,
                autoClose: 2500,
            });

        } catch (error) {
            toast.update(loadingToastId, {
                render: `Error: ${error.message}`,
                type: "error",
                isLoading: false,
                autoClose: 5000,
            });
            setTimeout(() => {
                window.location.reload();
            }, 2000);
        } finally {
            setLoading(false);
            toast.dismiss('loading-toast');
            
        }
    };

    // Handle deleting an image
    const handleDelete = async (imageUrl) => {
        setLoading(true);
        const loadingToastId = toast.loading("Deleting Image...");

        try {
            const token = localStorage.getItem('bearer_token');
            if (!token) {
                throw new Error('No token found. Please log in.');
            }

            // Extract the slide name after "/storage/"
            const slideName = imageUrl.split('/storage/')[1]; // Get the actual image name

            const response = await fetch(`${BASE_URL}/admin/delete-slider/1`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    image: slideName,
                })
            });

            if (!response.ok) {
                const result = await response.json();
                throw new Error(result.message || 'Failed to delete image');
            }

            // Success: Remove the image from state
            setImages(prevImages => prevImages.filter(img => img !== imageUrl));
            toast.update(loadingToastId, {
                render: "Image deleted successfully!",
                type: "success",
                isLoading: false,
                autoClose: 2500,
            });

        } catch (error) {
            toast.update(loadingToastId, {
                render: `Error: ${error.message}`,
                type: "error",
                isLoading: false,
                autoClose: 5000,
            });
        } finally {
            setLoading(false);
            toast.dismiss(loadingToastId);
        }
    };


    return (
        <div className="admin-sections-edit">
            <ToastContainer />
            <div className="pages-caption">
                <h1>Slides</h1>
            </div>
            <div className="admin-pages-caption">
                <h2>Edit "Home Carousel" Slides</h2>
            </div>
            <form onSubmit={handleSubmit} className="slide-image-form test-image-form">
                <div className="details-inputs">
                    {/* Map through existing images */}
                    {images.map((imageUrl, index) => (
                        <div key={index} className="image-box">
                            <div className="slide-image-control">
                                <h4>Slider Image {index + 1} <br /> <span className="image-spec">(700 x 400px)</span> <br />
                                    <span className="image-spec">2 Mb Max</span>
                                </h4>
                                <button type="button" onClick={() => handleDelete(imageUrl, `slide-${index + 1}`)}>
                                    {/* Delete Icon */}
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 18 18" fill="none">
                                        <path d="M15.7501 5.04748C15.7351 5.04748 15.7126 5.04748 15.6901 5.04748C11.7226 4.64998 7.76262 4.49998 3.84012 4.89748L2.31012 5.04748C1.99512 5.07748 1.71762 4.85248 1.68762 4.53748C1.65762 4.22248 1.88262 3.95248 2.19012 3.92248L3.72012 3.77248C7.71012 3.36748 11.7526 3.52498 15.8026 3.92248C16.1101 3.95248 16.3351 4.22998 16.3051 4.53748C16.2826 4.82998 16.0351 5.04748 15.7501 5.04748Z" fill="#D34A4A"/>
                                        <path d="M6.37458 4.29C6.34458 4.29 6.31458 4.29 6.27708 4.2825C5.97708 4.23 5.76708 3.9375 5.81958 3.6375L5.98458 2.655C6.10458 1.935 6.26958 0.9375 8.01708 0.9375H9.98208C11.7371 0.9375 11.9021 1.9725 12.0146 2.6625L12.1796 3.6375C12.2321 3.945 12.0221 4.2375 11.7221 4.2825C11.4146 4.335 11.1221 4.125 11.0771 3.825L10.9121 2.85C10.8071 2.1975 10.7846 2.07 9.98958 2.07H8.02458C7.22958 2.07 7.21458 2.175 7.10208 2.8425L6.92958 3.8175C6.88458 4.095 6.64458 4.29 6.37458 4.29Z" fill="#D34A4A"/>
                                        <path d="M11.4074 17.0626H6.59242C3.97492 17.0626 3.86992 15.6151 3.78742 14.4451L3.29992 6.89256C3.27742 6.58506 3.51742 6.31506 3.82492 6.29256C4.13992 6.27756 4.40242 6.51006 4.42492 6.81756L4.91242 14.3701C4.99492 15.5101 5.02492 15.9376 6.59242 15.9376H11.4074C12.9824 15.9376 13.0124 15.5101 13.0874 14.3701L13.5749 6.81756C13.5974 6.51006 13.8674 6.27756 14.1749 6.29256C14.4824 6.31506 14.7224 6.57756 14.6999 6.89256L14.2124 14.4451C14.1299 15.6151 14.0249 17.0626 11.4074 17.0626Z" fill="#D34A4A"/>
                                    </svg>
                                </button>
                            </div>
                            <div className="image-preview">
                                <img
                                    src={imagePreviews[index] || imageUrl}
                                    alt={`Preview ${index}`}
                                />
                            </div>
                        </div>
                    ))}
                    
                    {/* New Image Upload Section */}
                    <br /><br />
                    <label>
                        Upload New Image
                        <input
                            type="file"
                            onChange={handleNewImageChange}
                        />
                    </label>
                    {newImagePreview && (
                        <div className="image-preview">
                            <img src={newImagePreview} alt="New Image Preview" />
                        </div>
                    )}

                    <button type="submit" disabled={loading}>
                        {loading ? 'Updating ...' : 'Update Sliders'}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default AdminHomeCarousel;