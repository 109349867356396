import React from "react";

const ResearchApplicationRules = () => {
  return (
    <div className="research-application-rules">
      <h3>Application to the ethics and research committee for clearance of research involving human subjects, or patient records.</h3>
      <h6>All applications to the committee will only be considered if:</h6>
      <ol>
        <li>Response to this application is typed on one side of each sheet and all boxes are electronically ticked.</li>
        <li>The form is completed in full with the information requested. Where a question is not applicable, it is important to make it clear and not leave it blank.</li>
        <li>The application is signed by the applicant, applicant's supervisor (where appropriate), the Head of Department and the Head of Department/Unit where the research will be carried out.</li>
        <li>Seventeen collated sets of application form and accompanying documents are attached.</li>
        <li>The language used in the application is clear and simple to understand to lay members.</li>
        <li>All abbreviations should first be written in full.</li>
        <li>Three copies of research proposals are submitted.</li>
      </ol>
    </div>
  );
};

export default ResearchApplicationRules;