import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const checklistData = [
  {
    name: "Research Proposal",
    text: "Research Proposal/Dissertation (Three copies)",
  },
  {
    name: "Application Form",
    text: "Application Form (Seventeen copies only)",
  },
  {
    name: "Informed Consent",
    text: "Informed consent form",
  },
  {
    name: "Subject Information",
    text: "Subject information sheet",
  },
  {
    name: "Questionnaire Form",
    text: "Questionnaire form",
  },
  {
    name: "Proforma Form",
    text: "Proforma form",
  },
  {
    name: "Interview Form",
    text: "Interview form",
  },
  {
    name: "Advertisement",
    text: "Advertisement for research subjects",
  },
  {
    name: "Medical",
    text: "Medical/Dental Practitioners/Consultant information sheet/letter",
  },
  {
    name: "Data Sheet",
    text: "Data sheet for all drugs (one copy only)",
  },
  {
    name: "Compensation Statement",
    text: "Statement regarding compensation arrangements (one copy only)",
  },
  {
    name: "Clearance",
    text: "Clearance for use of isotopes and/or radiation",
  },
];

const ResearchEthicsForm = () => {
  const [formCompleted, setFormCompleted] = useState(true);
  const handleSubmit = async (e) => {
    e.preventDefault();
    let isCompleted = true;
    const selectedChecklist = {};
  
    // Collect the selected radio values for each checklist item
    checklistData.forEach((checklistItem) => {
      const radios = document.getElementsByName(checklistItem.name);
      const selectedRadio = Array.from(radios).find((radio) => radio.checked);
  
      if (!selectedRadio) {
        isCompleted = false;
      } else {
        selectedChecklist[checklistItem.name] = selectedRadio.value;
      }
    });
  
    setFormCompleted(isCompleted);
  
    if (isCompleted) {
  
      // Create the formData object
      const formData = {
        name: document.getElementsByName("researcher-name")[0].value,
        email: document.getElementsByName("researcher-email")[0].value,
        data: selectedChecklist,
      };
  
      // Convert formData to a raw JSON string
      const raw = JSON.stringify(formData);
  
      try {
        const response = await fetch("https://live-api.oauthc.gov.ng/v0.1/api/home/apply-research", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: raw,
        });
  
        if (response.ok) {
          toast.success("Form submitted successfully!");
        } else {
          const errorText = await response.text();
          console.error("Form submission failed:", errorText);
          toast.error("Form submission failed. Please try again.");
        }
      } catch (error) {
        console.error("Error submitting form:", error);
        toast.error("Error submitting form. Please check your connection.");
      }
    } else {
      console.log("Form not completed");
      toast.error("Form not completed. Please fill all required fields.");
    }
  };
  


  const handleChange = () => {
    if (!formCompleted) {
      setFormCompleted(true);
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="research-form">
        <form onSubmit={handleSubmit}>
          <div className="name-email-inputs">
            <input
              type="text"
              name="researcher-name"
              placeholder="Enter Name"
              onChange={handleChange}
            />
            <input
              type="email"
              name="researcher-email"
              placeholder="Enter Email"
              onChange={handleChange}
            />
          </div>

          <div className="research-checklists">
            <h6>Please indicate if the following have been enclosed by ticking the relevant box:</h6>
            <ol>
              {checklistData.map((checklistItem, index) => (
                <li key={index}>
                  <label htmlFor={checklistItem.name}>
                    <div className="input-options">
                      <span>{checklistItem.text}</span>
                      <input
                        type="radio"
                        name={checklistItem.name}
                        id={`${checklistItem.name}-yes`}
                        value="Yes"
                        onChange={handleChange}
                      />
                      <label htmlFor={`${checklistItem.name}-yes`}>Yes</label>
                      <input
                        type="radio"
                        name={checklistItem.name}
                        id={`${checklistItem.name}-no`}
                        value="No"
                        onChange={handleChange}
                      />
                      <label htmlFor={`${checklistItem.name}-no`}>No</label>
                      <input
                        type="radio"
                        name={checklistItem.name}
                        id={`${checklistItem.name}-na`}
                        value="Not Applicable"
                        onChange={handleChange}
                      />
                      <label htmlFor={`${checklistItem.name}-na`}>Not Applicable</label>
                    </div>
                  </label>
                </li>
              ))}
            </ol>
          </div>

          <div className="research-submit-button">
            <button type="submit">Submit</button>
            <p style={{ color: formCompleted ? "transparent" : "red" }}>
              Form not completed!
            </p>
          </div>

          <h5>N.B</h5>
          <p className="notification">
            Only the first twenty applications received will be considered, while others will be carried over to the next month.
          </p>
        </form>
      </div>
    </>
   
  );
};

export default ResearchEthicsForm;