import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ClipLoader } from "react-spinners";

const AdminResearchTable = ({ data, entityType, currentPage, itemsPerPage, setData }) => {
    const [statuses, setStatuses] = useState({}); // Track statuses in state
    const location = useLocation(); // Hook to get passed state

    useEffect(() => {
        const initialStatuses = data.reduce((acc, item) => {
            acc[item.id] = item.status;
            return acc;
        }, {});
        setStatuses(initialStatuses); // Initialize statuses from the data

        // Check if we got an updated status from the details page
        if (location.state && location.state.updatedId) {
            setStatuses(prevStatuses => ({
                ...prevStatuses,
                [location.state.updatedId]: 'viewed',
            }));
        }
    }, [data, location.state]);

    const handleStatusChange = (id) => {
        setStatuses(prevStatuses => ({
            ...prevStatuses,
            [id]: 'viewed', // Update status to viewed when admin navigates to details
        }));
    };

    if (!data || data.length === 0) {
        return (
            <div className="loading-spinner loading">
                <ClipLoader color="#005046" size={100} />
            </div>
        );
    }

    const sortedData = [...data].sort((a, b) => a.name.localeCompare(b.name));

    return (
        <>
            <table className="data-table">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Researcher Name</th>
                        <th>Researcher Email</th>
                        <th>Status</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {sortedData.map((item, index) => (
                        <tr key={`${item.id}-${index}`}>
                            <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                            <td>
                                <Link 
                                    to={`/admin/research-request/${item.id}`} 
                                    state={{ updatedId: item.id }} // Pass the id of the viewed request
                                    onClick={() => handleStatusChange(item.id)} // Update status on click
                                >
                                    {item.name}
                                </Link>
                            </td> 
                            <td className="admin-table-email">
                                {item.email}
                            </td>
                            <td className={
                                statuses[item.id] === 'viewed' ? 'confirmed' :
                                statuses[item.id] === 'pending' ? 'pending' : ''
                            }>
                                {statuses[item.id]}
                            </td>
                            <td>
                                <Link 
                                    to={`/admin/research-request/${item.id}`} 
                                    state={{ updatedId: item.id }} // Pass the id of the viewed request
                                    onClick={() => handleStatusChange(item.id)} // Update status on click
                                >
                                    <button>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                            <path d="M4.15499 14.64C3.69749 14.64 3.26999 14.4825 2.96249 14.19C2.57249 13.8225 2.38499 13.2675 2.45249 12.6675L2.72999 10.2375C2.78249 9.78004 3.05999 9.17254 3.38249 8.84254L9.53999 2.32504C11.0775 0.697537 12.6825 0.652537 14.31 2.19004C15.9375 3.72754 15.9825 5.33254 14.445 6.96004L8.28749 13.4775C7.97249 13.815 7.38749 14.13 6.92999 14.205L4.51499 14.6175C4.38749 14.625 4.27499 14.64 4.15499 14.64ZM11.9475 2.18254C11.37 2.18254 10.8675 2.54254 10.3575 3.08254L4.19999 9.60754C4.04999 9.76504 3.87749 10.14 3.84749 10.3575L3.56999 12.7875C3.53999 13.035 3.59999 13.2375 3.73499 13.365C3.86999 13.4925 4.07249 13.5375 4.31999 13.5L6.73499 13.0875C6.95249 13.05 7.31249 12.855 7.46249 12.6975L13.62 6.18004C14.55 5.19004 14.8875 4.27504 13.53 3.00004C12.93 2.42254 12.4125 2.18254 11.9475 2.18254Z" fill="black"/>
                                            <path d="M13.0048 8.21263C12.9898 8.21263 12.9673 8.21263 12.9523 8.21263C10.6123 7.98013 8.72984 6.20263 8.36984 3.87763C8.32484 3.57013 8.53484 3.28513 8.84234 3.23263C9.14984 3.18763 9.43484 3.39763 9.48734 3.70513C9.77234 5.52013 11.2423 6.91513 13.0723 7.09513C13.3798 7.12513 13.6048 7.40263 13.5748 7.71013C13.5373 7.99513 13.2898 8.21263 13.0048 8.21263Z" fill="black"/>
                                            <path d="M15.75 17.0625H2.25C1.9425 17.0625 1.6875 16.8075 1.6875 16.5C1.6875 16.1925 1.9425 15.9375 2.25 15.9375H15.75C16.0575 15.9375 16.3125 16.1925 16.3125 16.5C16.3125 16.8075 16.0575 17.0625 15.75 17.0625Z" fill="black"/>
                                        </svg>
                                    </button>
                                </Link>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <ToastContainer />
        </>
    );
};

export default AdminResearchTable;