import React, { useState, useEffect } from 'react';
import jsPDF from 'jspdf';
import { useParams, useNavigate } from 'react-router-dom'; // Removed Link, kept useNavigate
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ClipLoader } from 'react-spinners';

const BASE_URL = 'https://live-api.oauthc.gov.ng/v0.1/api/admin';

const AdminResearchRequest = () => {
    const { id } = useParams();
    const navigate = useNavigate(); // Declare useNavigate hook
    const [loading, setLoading] = useState(true);
    const [researcherData, setResearcherData] = useState(null);

    useEffect(() => {
        const fetchResearcherData = async () => {
            try {
                const token = localStorage.getItem('bearer_token');
                if (!token) {
                    throw new Error('No token found. Please log in.');
                }

                const response = await fetch(`${BASE_URL}/reserach-requests/${id}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch researcher data');
                }

                const data = await response.json();
                setResearcherData(data);
            } catch (error) {
                toast.error(`Error: ${error.message}`);
            } finally {
                setLoading(false);
            }
        };

        fetchResearcherData();
    }, [id]);

    // Updated handleBackClick function
    const handleBackClick = () => {
        // Navigate back to the research requests table
        navigate('/admin/research-request'); // Ensure the correct path

        // Optionally trigger a window refresh after navigating
        setTimeout(() => {
            window.location.reload();
        }, 500); 
    };

    if (loading) {
        return (
            <div className="loading-spinner loading">
                <ClipLoader color="#005046" size={100} />
            </div>
        );
    }

    const handleDownload = () => {
        const doc = new jsPDF();
        const initialFontSize = 16; // Set the initial font size
        doc.setFontSize(initialFontSize);
        
        const details = researcherData.data; // Assuming this holds your fetched data

        // Add the title with increased font size and bold style
        doc.setFont("helvetica", "bold"); // Set font to bold
        doc.text(`Research & Ethics Form Details`, 10, 20); // Place the title
        doc.setFont("helvetica", "normal"); // Reset font to normal
        let yPos = 30;
        const lineHeight = 10; // Space between lines
    
        // Adding researcher details to PDF
        doc.setFontSize(initialFontSize); // Set the font size back to the initial value
        doc.text(`Researcher Name: ${details.name}`, 10, yPos);
        yPos += lineHeight;
        doc.text(`Researcher Email: ${details.email}`, 10, yPos);
        yPos += lineHeight;
        doc.text(`Research Proposal: ${details.data["Research Proposal"] || "N/A"}`, 10, yPos);
        yPos += lineHeight;
        doc.text(`Application Form: ${details.data["Application Form"] || "N/A"}`, 10, yPos);
        yPos += lineHeight;
        doc.text(`Informed Consent Form: ${details.data["Informed Consent"] || "N/A"}`, 10, yPos);
        yPos += lineHeight;
        doc.text(`Subject Information Sheet: ${details.data["Subject Information"] || "N/A"}`, 10, yPos);
        yPos += lineHeight;
        doc.text(`Questionnaire Form: ${details.data["Questionnaire Form"] || "N/A"}`, 10, yPos);
        yPos += lineHeight;
        doc.text(`Proforma Form: ${details.data["Proforma Form"] || "N/A"}`, 10, yPos);
        yPos += lineHeight;
        doc.text(`Interview Form: ${details.data["Interview Form"] || "N/A"}`, 10, yPos);
        yPos += lineHeight;
        doc.text(`Advertisement for Research: ${details.data["Advertisement"] || "N/A"}`, 10, yPos);
        yPos += lineHeight;
        doc.text(`Medical/Dental Practitioners Consult Info Sheet: ${details.data["Medical"] || "N/A"}`, 10, yPos);
        yPos += lineHeight;
        doc.text(`Data Sheet: ${details.data["Data Sheet"] || "N/A"}`, 10, yPos);
        yPos += lineHeight;
        doc.text(`Statement of Compensation Arrangements: ${details.data["Compensation Statement"] || "N/A"}`, 10, yPos);
        yPos += lineHeight;
        doc.text(`Clearance for Isotopes or Radiation: ${details.data["Clearance"] || "N/A"}`, 10, yPos);
        
        // Save the generated PDF
        doc.save(`${details.name}-request.pdf`);
    };

    if (!researcherData || !researcherData.data || !researcherData.data.data) {
        return <div>No data found for this researcher.</div>;
    }

    const researchDetails = researcherData.data;

    return (
        <>
            <ToastContainer />
            <div>
                <div className="pages-caption">
                    <h1>Researchers Request Details</h1>
                </div>
                <div className="back" onClick={handleBackClick} style={{ cursor: 'pointer' }}> 
                    <FontAwesomeIcon icon={faChevronLeft} /> Back 
                </div>
                <form className='test-image-form details-page-form'>
                    <div className="details-inputs">
                        <label>
                            Researcher Name: 
                            <input
                                type="text"
                                value={researchDetails.name}
                                readOnly
                            />
                        </label>
                        <label>
                            Researcher Email: 
                            <input
                                type="text"
                                value={researchDetails.email}
                                readOnly
                            />
                        </label>
                        <label>
                            Research Proposal: 
                            <input
                                type="text"
                                value={researchDetails.data["Research Proposal"] || "N/A"}
                                readOnly
                            />
                        </label>
                        <label>
                            Application Form: 
                            <input
                                type="text"
                                value={researchDetails.data["Application Form"] || "N/A"}
                                readOnly
                            />
                        </label>
                        <label>
                            Informed Consent Form: 
                            <input
                                type="text"
                                value={researchDetails.data["Informed Consent"] || "N/A"}
                                readOnly
                            />
                        </label>
                        <label>
                            Subject Information Sheet: 
                            <input
                                type="text"
                                value={researchDetails.data["Subject Information"] || "N/A"}
                                readOnly
                            />
                        </label>
                        <label>
                            Questionnaire Form: 
                            <input
                                type="text"
                                value={researchDetails.data["Questionnaire Form"] || "N/A"}
                                readOnly
                            />
                        </label>
                        <label>
                            Proforma Form: 
                            <input
                                type="text"
                                value={researchDetails.data["Proforma Form"] || "N/A"}
                                readOnly
                            />
                        </label>
                        <label>
                            Interview Form: 
                            <input
                                type="text"
                                value={researchDetails.data["Interview Form"] || "N/A"}
                                readOnly
                            />
                        </label>
                        <label>
                            Advertisement for Research: 
                            <input
                                type="text"
                                value={researchDetails.data["Advertisement"] || "N/A"}
                                readOnly
                            />
                        </label>
                        <label>
                            Medical/Dental Practitioners Consult Info Sheet: 
                            <input
                                type="text"
                                value={researchDetails.data["Medical"] || "N/A"}
                                readOnly
                            />
                        </label>
                        <label>
                            Data Sheet for All Drugs: 
                            <input
                                type="text"
                                value={researchDetails.data["Data Sheet"] || "N/A"}
                                readOnly
                            />
                        </label>
                        <label>
                            Statement of Compensation Arrangements: 
                            <input
                                type="text"
                                value={researchDetails.data["Compensation Statement"] || "N/A"}
                                readOnly
                            />
                        </label>
                        <label>
                            Clearance for Isotopes or Radiation: 
                            <input
                                type="text"
                                value={researchDetails.data["Clearance"] || "N/A"}
                                readOnly
                            />
                        </label>
                        <button type="button" className="download-research" onClick={handleDownload}>Download</button>
                    </div>
                </form>
            </div>
        </>
    );
};

export default AdminResearchRequest;